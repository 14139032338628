
import { Capabilities } from 'Integrations/Components/Components';
import React, { } from 'react';
import { Input } from 'reactstrap';

import { CopyButton, LazyFormRow } from 'Shared/Components';
import logo from './logo.png';
import { useTranslation } from 'react-i18next';

export default {
    friendlyName: "iRaiser CFP (Kentaa)",
    description: Description,
    url: "https://www.iraiser.com/nl/",
    logo: logo,
    type: "Ifunds.Crowdfunding.Kentaa",
    capabilities: [Capabilities.Donation],
    displayComponent: Display,
    addComponent: Add
}

function Description(){
    const {t} = useTranslation('integrations');
    return (<>{t('kentaa.description')}</>);
}

function Display({ data }) {
    const {t} = useTranslation('integrations');

    return (<>
        <p>{t('kentaa.details.part1')}</p>
        <p>{t('kentaa.details.part2')}</p>
        <CopyButton value={data.webhook?.endpoint + '?api_key=' + data.webhook?.apiKey} />
    </>);
}

function Add({ onChange, details }) {
    const {t} = useTranslation(['integrations']);

    function onChangeApiKey(e) {
        const apiKey = e.target.value;
        onChange({ details: { apiKey: apiKey }, isValid: !!apiKey });
    }

    return (    
    <LazyFormRow label={t('labels.api_key')} helpUrl={t('kentaa.help.apikey')}>
        <Input type="text" className="form-control" value={details?.apiKey} onChange={onChangeApiKey} placeholder={t('labels.api_key')} />
    </LazyFormRow>);
}
