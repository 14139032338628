import flag from './flag.svg';

export const language = {
    key: 'de',
    name: 'Deutsch',
    flag: flag
};

export const translations = {
    home: {
        intro: {
            title: "Let's Engage",
            subtitle: "Willkommen im Ifunds-Verwaltungsportal.",
            content: "Über dieses Portal können die von Ifunds angebotenen Dienste verwaltet werden:"
        }
    },
    environment: {
        card: {
            title: "Umgebung",
            subtitle: "Konfigurieren Sie die Quellumgebung",
            refresh_config: "Konfiguration aktualisieren",
            re_authorize: "Erneut autorisieren"
        },
        labels: {
            type: "Typ",
            status: "Status",
            unique_name: "Eindeutiger Name",
            api_url: "API-URL",
            authorization: "Autorisierung"
        },
        status: {
            success: "Aktiv",
            needs_attention: "Benötigt Aufmerksamkeit"
        },
        onboarding: {
            error: {
                already_configured: "Die Umgebung wurde bereits konfiguriert",
                generic: "Konnte nicht erstellt werden"
            },
            title: "Neue Umgebung konfigurieren",
            select_environment: "Umgebung auswählen",
            environment: "Umgebung",
            product: "Produkt",
            products: {
                engage365: "Engage 365",
                c4np: "Cloud 4 Nonprofit"
            }
        },
        authorization: {
            authorized: "Ja",
            unauthorized: "Nein"
        }
    },
    buttons: {
        authorize: {
            authorized: "Autorisiert",
            authorizing: "Autorisierung läuft",
            authorize: "Autorisieren",
            failure: "Autorisierung fehlgeschlagen"
        }
    },
    main: {
        brand: {
            title: "Verwaltungsportal"
        },
        menu: {
            home: "Startseite",
            smart_storage: "Intelligenter Speicher",
            integrations: "Integrationen",
            services: "Dienste",
            show_less: "Weniger anzeigen",
            show_more: "Mehr anzeigen",
            connect_new_environment: "Neue Umgebung verbinden",
            loading_environments: "Umgebungen werden geladen...",
            logoff: "Abmelden",
            logon: "Anmelden",
            attention: "Achtung"
        }
    },
    general: {
        go_back: "Zurück",
        yes: "Ja",
        no: "Nein",
        copy: "Kopieren",
        error: "Fehler: {{message}}",
        delete: "Löschen",
        close: "Schließen",
        create: "Erstellen",
        subscribed: "Abonniert",
        subscribe: "Abonnieren"
    },
    integrations: {
        labels: {
            friendly_name: "Anzeigename",
            api_key: "API-Schlüssel"
        },
        overview: {
            add_integration: "Integration hinzufügen",
            loading_integrations: "Integrationen werden geladen..."
        },
        connector: {
            set_as_default: "Als Standard festlegen",
            show_details: "Details anzeigen",
            delete_confirmation: "Möchten Sie diese Verbindung wirklich löschen?",
            sync: "Synchronisieren",
            sync_confirmation: "Möchten Sie wirklich synchronisieren?"
        },
        capability: {
            'Donation_Link': "Spendenlink",
            'Donation': "Spende",
            'Donation Agreement': 'Donation Agreement',
            'Payment Link': 'Zahlungslink',
            'Payment': 'Zahlung',
        },
        marketplace: {
            get_it_now: "Jetzt holen",
            visit_website: "Website besuchen",
            setup_header: "{{name}} einrichten",
            connect: "Verbinden"
        },
        tikkie: {
            description: "Generieren Sie Tikkie-Spendenlinks für Ihre Spender und erhalten Sie Echtzeit-Tikkies in CRM.",
            labels: {
                app_token: "App-Token",
                sandbox: "Sandbox",
                production: "Produktion"
            }
        },
        fundiz: {
            description: "Generieren Sie Fundiz-Spendenlinks aus CRM und erhalten Sie Spenden in Echtzeit in CRM.",
            labels: {
                website_key: "Website-Schlüssel"
            }
        },
        kentaa: {
            description: "Erhalten Sie Spenden von iRaiser CFP Forms oder P2P (früher Kentaa) in Echtzeit in CRM.",
            details: {
                part1: "Um Daten von iRaiser abzurufen, muss ein Webhook konfiguriert werden.",
                part2: "Bitte wenden Sie sich an den iRaiser-Support mit der Anfrage, die Webhook-URL (hinter dem Kopieren-Button) für die Umgebung zu konfigurieren."
            },
            help: {
                apikey: "https://support.community-fundraising.com/articles/api?locale=en#key%20generation"
            }
        },
        digicollect: {
            description: "Erhalten Sie Spenden über DigiCollect-Sammelboxen in Echtzeit in CRM.",
            details: {
                part1: "Um Daten von DigiCollect abzurufen, muss ein Webhook konfiguriert werden.",
                part2: "Bitte wenden Sie sich an den iRaiser-Support mit der Anfrage, die Webhook-URL (hinter dem Kopieren-Button) für die Umgebung zu konfigurieren."
            },
            help: {
                apikey: "https://api.digicollect.nl/v1/doc#authentication"
            }
        },
        periodiekschenken: {
            description: "Receive donation agreements realtime from periodiekschenken.nl",
            setup: "Please use one of the following buttons to retrieve instructions on configuring the connection on the Periodiek Schenken side.",
            sendmail: 'Send mail',
            labels: {
                api_key: 'Api Key',
                api_secret: 'Api Secret',
                url: 'Url',
                url_placeholder: 'https://<accountname>.formulierenserver.nl'
            },
        }
    },
    accountholder_parsing: {
        title: "Kontoinhabername",
        description: "Verbesserte Erkennung von Kontoinhabernamen.",
        checking: "Status wird geladen..."
    },
    features: {
        "Crowdfunding": "Crowdfunding",
        "Kentaa": "Kentaa",
        "Connector Sync": "Connector-Synchronisierung",
        "Show Pricing": "Preise anzeigen",
        "Hosted processing": 'Managed Processing',
        "Deployments": "Bereitstellungen",
        "Cloud 4 Non-Profit": "Cloud for Nonprofit",
        "Accountholder Parsing": "Kontoinhabername",
        "Smart Storage": "Smart storage"
    }
}
