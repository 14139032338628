import flag from './flag.svg';

export const language = {
    key: 'nl',
    name: 'Nederlands',
    flag: flag
}

export const translations = {
    home: {
        intro: {
            title: "Let's engage",
            subtitle: "Welkom op het ifunds management portaal.",
            content: "In dit portaal kunnen de volgende door ifunds aangeboden diensten beheerd worden:"
        }
    },
    environment: {
        card: {
            title: "Omgeving",
            subtitle: "Eigenschappen van de omgeving",
            refresh_config: "Configuratie vernieuwen",
            re_authorize: "Her-authenticeren"
        },
        labels: {
            type: "Type",
            status: "Status",
            unique_name: "Unieke naam",
            api_url: "Api url",
            authorization: "Autorisatie"
        },
        status: {
            success: "Actief",
            needs_attention: "Actie nodig"
        },
        onboarding: {
            error: {
                already_configured: "Omgeving is al eerder toegevoegd",
                invalid_environment_type: "Product wordt niet ondersteund",
                generic: "Omgeving kon niet worden aangemaakt",
            },
            title: "Nieuwe omgeving toevoegen",
            select_environment: "Selecteer de nieuwe omgeving",
            environment: "Omgeving",
            product: "Product",
            products: {
                engage365: 'Engage 365',
                c4np: 'Cloud for Nonprofit'
            }
        }
    },
    buttons: {
        authorize: {
            authorized: "Geautoriseerd",
            authorizing: "Autoriseren",
            authorize: "Autoriseren",
            failure: "Autorisatie mislukt"
        }
    },
    main: {
        brand: {
            title: "Management portaal"
        },
        menu: {
            home: "Home",
            smart_storage: "Smart Storage",
            integrations: "Integraties",
            services: "Diensten",
            show_less: "Verbergen",
            show_more: "Openen",
            connect_new_environment: "Nieuwe omgeving toevoegen",
            loading_environments: "Omgevingen ophalen...",
            logoff: "Uitloggen",
            logon: "Inloggen",
            attention: "Attentie"
        }
    },
    general: {
        go_back: "Terug",
        yes: "Ja",
        no: "Nee",
        copy: "Kopiëren",
        error: "Fout: {{message}}",
        delete: "Verwijderen",
        close: "Sluiten",
        create: "Toevoegen",
        subscribed: "Geabonneerd",
        subscribe: "Abonneren"
    },
    integrations: {
        labels: {
            friendly_name: "Gebruiksvriendelijke naam",
            api_key: "Api sleutel"
        },
        overview: {
            add_integration: "Integratie toevoegen",
            loading_integrations: "Integraties ophalen..."
        },
        connector: {
            set_as_default: 'Standaard',
            show_details: 'Details weergeven',
            delete_confirmation: 'Weet je zeker dat je deze integratie wil verwijderen?',
            sync: "Synchroniseren",
            sync_confirmation: "Weet je zeker dat je deze integratie wil synchroniseren?"
        },
        capability: {
            'Donation Link': 'Donatielink',
            'Donation': 'Donatie',
            'Donation Agreement': 'Periodieke Schenking',
            'Payment Link': 'Betaallink',
            'Payment': 'Betaling',
        },
        marketplace: {
            get_it_now: "Nu toevoegen",
            visit_website: "Naar website",
            setup_header: "{{name}} instellen",
            connect: "Instellen"
        },
        tikkie: {
            description: "Genereer Tikkie betaalverzoeken voor je donateurs vanuit crm, en ontvang betaalde tikkies direct in crm.",
            labels: {
                app_token: 'App token',
                sandbox: 'Sandbox',
                production: 'Productie'
            }
        },
        fundiz: {
            description: "Genereer fundiz donatie links voor je donateurs vanuit crm, en ontvang de betaalde donaties direct in crm.",
            labels: {
                website_key: 'Website id'
            }
        },
        kentaa: {
            description: "Ontvang donaties gedaan in iRaiser CFP Forms en P2P (voorheen Kentaa) direct in crm.",
            details: {
                part1: "Voor het ontvangen van data van iraiser moet er een webhook ingesteld worden.",
                part2: "Neem a.u.b. contact op met iraiser support met het verzoek om de webhook url (achter de 'kopiëren' knop) in te stellen voor de omgeving."
            },
            help: {
                apikey: "https://support.community-fundraising.com/articles/api?locale=nl#keygenereren"
            }
        },
        digicollect: {
            description: "Ontvang donaties via DigiCollect collectebussen direct in crm",
            details: {
                part1: "Voor het ontvangen van data van digicollect moet er een webhook ingesteld worden.",
                part2: "Neem a.u.b. contact op met iRaiser support met het verzoek om de webhook url (achter de 'kopiëren' knop) in te stellen voor de omgeving."
            },
            help: {
                apikey: "https://api.digicollect.nl/v1/doc#authentication"
            }
        },
        periodiekschenken: {
            description: "Ontvang realtime periodieke schenkings overeenkomsten vanaf periodiekschenken.nl",
            setup: "Gebruik 1 van de volgende knoppen om instructies te krijgen voor het instellen van de koppeling bij periodiekschenken.nl.",
            sendmail: 'Mail sturen',
            labels: {
                api_key: 'Api Key',
                api_secret: 'Api Secret',
                url: 'Url',
                url_placeholder: 'https://<accountnaam>.formulierenserver.nl'
            },
        }
    },
    accountholder_parsing: {
        title: "Rekeninghouder",
        description: "Verbeterde herkenning rekeninghouder namen.",
        checking: "Status ophalen..."
    },
    features: {
        "Crowdfunding": 'Crowdfunding',
        "Kentaa": 'Kentaa',
        "Connector Sync": 'Connector Sync',
        "Show Pricing": 'Toon prijzen',
        "Hosted processing": 'Managed Processing',
        "Deployments": 'Installaties',
        "Cloud 4 Non-Profit": 'Cloud 4 Non-Profit',
        "Accountholder Parsing": "Rekeninghouder",
        "Smart Storage": "Smart storage"
    }
}